import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CommandesService } from 'src/app/services/commandes/commandes.service';

@Component({
  selector: 'app-facture-commande',
  templateUrl: './facture-commande.component.html',
  styleUrls: ['./facture-commande.component.css']
})
export class FactureCommandeComponent implements OnInit {

  cmd: any = {}
  cmdID: string = '';

  constructor(private route: ActivatedRoute,
    private cmdsService: CommandesService) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      this.cmdID = params.cmd;
      this._fetchCmd();
      // console.log(this.cmd);
    });
  }

  _fetchCmd() {
    // console.log(cid);
    this.cmdsService.getCMD(this.cmdID).then((data: any) => {
      // console.log("dataaaa : ")
      // console.log(data)
      if (data) {
        this.cmd = data;
      } else {
        alert('Aucune commande valide');
        // location.href = '/commandes';
      }
    });
  }

}
