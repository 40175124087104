// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

var baseURLAPI = 'https://logistic.marketbey.tn/api/v0/';

export const environment = {
  production: false,

  // API CMDS
  apiAddCmd: baseURLAPI + 'cmds/add-cmd',
  apiExchangeCmd: baseURLAPI + 'cmds/exchange-cmd',
  // apiAddCmds: baseURLAPI + 'cmds/add-cmds',
  apiOneCmd: baseURLAPI + 'cmds/cmd',
  apiAllCmds: baseURLAPI + 'cmds/list-cmds',
  apiAllCmdsByClient: baseURLAPI + 'cmds/list-cmds-client',
  apiAllCmdsByStatusAndClient: baseURLAPI + 'cmds/cmds-status-client',
  apiAllCmdsByStatus: baseURLAPI + 'cmds/cmds-all-status',
  apiStatus: baseURLAPI + 'cmds/liststatus',
  apiSearchCmd: baseURLAPI + 'cmds/search',
  apiFilterCmds: baseURLAPI + 'cmds/filter-cmds',
  apiStatsCmds: baseURLAPI + 'cmds/statistics-cmds',
  apiUpdateCmdStatus: baseURLAPI + 'cmds/updatecmdstatus',
  apiAddComment: baseURLAPI + 'cmds/add-comment',
  apiAllComments: baseURLAPI + 'cmds/all-comments',
  
  apiAddAccountClient: baseURLAPI + "client/add-account-client",
  apiHasDemandeClient: baseURLAPI + "client/has-demande-client",

  // API CLIENT
  apiGetFraisClient: baseURLAPI + 'client/frais-client',
  apiUsersAuth: baseURLAPI + 'client/auth-client',
  apiInfosUser: baseURLAPI + "client/one-client",
  apiCmdsNotPaiedAndNotReturned: baseURLAPI + "client/details-cmds-client",
  apiAccountsClient: baseURLAPI + "client/accounts-client",

  // API MARKETBEY
  apiOneCmdByID: '', // https://api.marketbey.tn:2043/tracking/cmdbyref
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
