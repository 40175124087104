import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { ClientsService } from 'src/app/services/clients/clients.service';
import { CommandesService } from 'src/app/services/commandes/commandes.service';

@Component({
  selector: 'app-commandes',
  templateUrl: './commandes.component.html',
  styleUrls: ['./commandes.component.css']
})
export class CommandesComponent implements OnInit {
  villes = ["Selectionnez une ville",
    'Ariana',
    // 'Béja',
    'Ben Arous',
    // 'Bizerte',
    // 'Gabès',
    // 'Gafsa',
    // 'Jendouba',
    // 'Kairouan',
    // 'Kasserine',
    // 'Kébili',
    // 'Kef',
    // 'Mahdia',
    'Manouba',
    // 'Médenine',
    'Monastir',
    // 'Nabeul',
    'Sfax',
    // 'Sidi Bouzid',
    // 'Siliana',
    'Sousse',
    // 'Tataouine',
    // 'Tozeur',
    'Tunis',
    // 'Zaghouan'
  ];

  status = ["Selectionnez status", "validated", "transporting_depot", "prepared", "submited", "transporting", "transported", "arrived", "delivered", "paied", "returned", "refused", "canceled"];
  selectedVille: string = '';
  selectedStatus: string = '';
  currentPage: number = 0
  totalPages: number = 1
  pages: Array<number> = [];
  pagesDisplaied: Array<number> = [];

  cmdList: Array<any> = [];
  statusCmds: any = []
  client: any = null

  constructor(private cmdsService: CommandesService,
    private clientService: ClientsService) { }

  ngOnInit(): void {
    this.cmdsService.getLabeledStatus().then(
      (lst) => {
        this.statusCmds = lst
      }
    )

    this.getInfosUser()
    // this.listCmdsClient()
    this.searchCmds("", "", "", "")
  }

  goToNewTab(idCmd, route) {
    this.cmdsService.goToNewTab(idCmd, route)
  }

  selectChangeStatus(event: any) {
    if (event.target.value == "Selectionnez status") {
      this.selectedStatus = ""
    } else {
      this.selectedStatus = event.target.value;
    }
  }

  selectChangeVille(event: any) {
    if (event.target.value == "Selectionnez une ville") {
      this.selectedVille = "";
    } else {
      this.selectedVille = event.target.value;
    }
  }
  
  async getInfosUser() {
    await this.clientService.getUserInfos().then((res: any) => {
      // console.log("getUserInfos");
      // console.log(res);
      if (res) {
        this.client = res;
      }
    });
  }

  // async listCmdsClient() {
  //   this.pages = []
  //   let userId = await this.clientService.getIdUser()
  //   await this.cmdsService.getCmdsByClient(userId).then((res: any) => {
  //     console.log(res)
  //     if (res) {
  //       this.cmdList = res;
  //     }
  //   });
  // }
  
  splicePages() {
    // console.log("currentPage : ")
    //     console.log(this.currentPage)
    if(this.pages.length > 10) {
      // if(this.currentPage < this.pages.length-10) {
      //   this.pagesDisplaied = this.pages.splice(this.currentPage, 10);
      //   console.log("if 1 pagesDisplaied : ")
      //   console.log(this.pagesDisplaied)
      // } else 
      if(this.currentPage < 5) {
        this.pagesDisplaied = this.pages.splice(0, 10);
        // console.log("if 1 pagesDisplaied : ")
        // console.log(this.pagesDisplaied)
      } else if(this.currentPage >= 5 && this.currentPage < this.pages.length-5) {
        this.pagesDisplaied = this.pages.splice(this.currentPage-4, 10);
        // console.log("if 2 pagesDisplaied : ")
        // console.log(this.pagesDisplaied)
      } else {
        this.pagesDisplaied = this.pages.splice(this.pages.length-10, 10);
        // console.log("else pagesDisplaied : ")
        // console.log(this.pagesDisplaied)
      }
    } else {
      this.pagesDisplaied = this.pages
    }
  }

  async filterCmds(ref, numero, datein, datefin) {
    this.pages = []
    this.pagesDisplaied = []
    this.currentPage = 0
    this.searchCmds(ref.value, numero.value, datein.value, datefin.value);
  }
  
  async searchCmds(ref, numero, datein, datefin) {
    let userId = await this.clientService.getIdUser()
    await this.cmdsService.filterCmds(this.currentPage, userId, ref, 
        this.selectedStatus, this.selectedVille, numero, datein, datefin).then((res: any) => {
      if (res) {
        // console.log("resssssssss : ")
        // console.log(res)
        this.cmdList = res.items;
        this.totalPages = res.totalPages
        this.currentPage = res.currentPage
        this.pages = Array.from(Array(res.totalPages)).map((x, i) => i )
        this.splicePages()
      }
    });
  }
  nextPage(ref, numero, datein, datefin) {
    this.currentPage++
    this.searchCmds(ref.value, numero.value, datein.value, datefin.value);
  }

  previousPage(ref, numero, datein, datefin) {
    this.currentPage--
    this.searchCmds(ref.value, numero.value, datein.value, datefin.value);
  }

  selectPage(page, ref, numero, datein, datefin) {
    this.currentPage = page
    this.searchCmds(ref.value, numero.value, datein.value, datefin.value);
  }

  async updateStatusCmd(cmd, status) {
    // this.notif.notify('success', 'Commande mis à jour avec succès');
    // return;
    if (confirm('Etes vous sûre?'))
      await this.cmdsService.updateCmdStatus(cmd, status).then((res) => {
        if (res) {
          alert('Commande mis à jour avec succès');
          // cmd.etat_cmd = "canceled"
          this.searchCmds("", "", "", "")
        }
        else {
          alert("Une erreur s'est produite");
        }
      });
  }

}
