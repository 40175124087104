import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ClientsService } from 'src/app/services/clients/clients.service';
import { CommandesService } from 'src/app/services/commandes/commandes.service';

@Component({
  selector: 'app-recherche',
  templateUrl: './recherche.component.html',
  styleUrls: ['./recherche.component.css']
})
export class RechercheComponent implements OnInit {

  // villes = ["Selectionnez une ville",
  //   'Ariana',
  //   // 'Béja',
  //   'Ben Arous',
  //   // 'Bizerte',
  //   // 'Gabès',
  //   // 'Gafsa',
  //   // 'Jendouba',
  //   // 'Kairouan',
  //   // 'Kasserine',
  //   // 'Kébili',
  //   // 'Kef',
  //   // 'Mahdia',
  //   'Manouba',
  //   // 'Médenine',
  //   'Monastir',
  //   // 'Nabeul',
  //   'Sfax',
  //   // 'Sidi Bouzid',
  //   // 'Siliana',
  //   'Sousse',
  //   // 'Tataouine',
  //   // 'Tozeur',
  //   'Tunis',
  //   // 'Zaghouan'
  // ];

  // status = ["Selectionnez status", "validated", "transporting_depot", "prepared", "submited", "transporting", "transported", "arrived", "delivered", "paied", "returned", "refused", "canceled"];
  // selectedVille: string = '';
  // selectedStatus: string = '';
  // currentPage: number = 0
  // totalPages: number = 1
  // pages: Array<number> = [];

  listCmds: any = []
  statusCmds: any = []

  constructor(private cmdsService: CommandesService,
    private clientService: ClientsService) { }

  ngOnInit(): void {
    this.cmdsService.getLabeledStatus().then(
      (lst) => {
        this.statusCmds = lst
      }
    )
  }

  goToNewTab(idCmd, route) {
    this.cmdsService.goToNewTab(idCmd, route)
  }
  
  // selectChangeStatus(event: any) {
  //   if (event.target.value == "Selectionnez status") {
  //     this.selectedStatus = ""
  //   } else {
  //     this.selectedStatus = event.target.value;
  //   }
  // }

  // selectChangeVille(event: any) {
  //   if (event.target.value == "Selectionnez une ville") {
  //     this.selectedVille = "";
  //   } else {
  //     this.selectedVille = event.target.value;
  //   }

  // }

  async listCmdsClient(keyword) {
    // this.pages = []
    if (keyword.value != "") {
      let userId = await this.clientService.getIdUser()
      await this.cmdsService.searchCmd(userId, keyword.value).then((res: any) => {
        // console.log(res)
        if (res) {
          this.listCmds = res;
        }
      });
    }
  }

  // async filterCmds(ref, numero, datein, datefin) {
  //   this.currentPage = 0
  //   this.searchCmds(ref, numero, datein, datefin);
  // }
  // async searchCmds(ref, numero, datein, datefin) {
  //   let userId = await this.clientService.getIdUser()
  //   await this.cmdsService.filterCmds(this.currentPage, userId, ref.value, 
  //       this.selectedStatus, this.selectedVille, numero.value, datein.value, datefin.value).then((res: any) => {
  //     if (res) {
  //       console.log("resssssssss : ")
  //       console.log(res)
  //       this.listCmds = res.items;
  //       this.totalPages = res.totalPages
  //       this.currentPage = res.currentPage
  //       this.pages = Array.from(Array(res.totalPages)).map((x, i) => i )
  //       console.log("this.pages")
  //       console.log(this.pages)
  //     }
  //   });
  // }
  // nextPage(ref, numero, datein, datefin) {
  //   this.currentPage++
  //   this.searchCmds(ref, numero, datein, datefin);
  // }

  // previousPage(ref, numero, datein, datefin) {
  //   this.currentPage--
  //   this.searchCmds(ref, numero, datein, datefin);
  // }

  // selectPage(page, ref, numero, datein, datefin) {
  //   this.currentPage = page
  //   this.searchCmds(ref, numero, datein, datefin);
  // }
}
