import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ClientsService } from 'src/app/services/clients/clients.service';
import { CommandesService } from 'src/app/services/commandes/commandes.service';

@Component({
  selector: 'app-exchange-commande',
  templateUrl: './exchange-commande.component.html',
  styleUrls: ['./exchange-commande.component.css']
})
export class ExchangeCommandeComponent implements OnInit {

  villes = ["Selectionnez une ville",
    'Ariana',
    // 'Béja',
    'Ben Arous',
    // 'Bizerte',
    // 'Gabès',
    // 'Gafsa',
    // 'Jendouba',
    // 'Kairouan',
    // 'Kasserine',
    // 'Kébili',
    // 'Kef',
    // 'Mahdia',
    'Manouba',
    // 'Médenine',
    'Monastir',
    // 'Nabeul',
    'Sfax',
    // 'Sidi Bouzid',
    // 'Siliana',
    'Sousse',
    // 'Tataouine',
    // 'Tozeur',
    'Tunis',
    // 'Zaghouan'
  ];
  totalCmd: number = 0;
  frais: any = {}
  client: any = null

  cmd: any = {}
  cmdID: string = '';

  constructor(private fb: FormBuilder,
    private route: ActivatedRoute,
    private clientService: ClientsService,
    private cmdService: CommandesService) {
  }

  async ngOnInit() {
    await this.getInfosUser()
    this.route.queryParams.subscribe((params) => {
      this.cmdID = params.cmd;
      this._fetchCmd();
      // console.log(this.cmd);
    });
    // this.getFraisClient()
  }

  _fetchCmd() {
    // console.log(cid);
    this.cmdService.getCMD(this.cmdID).then((data: any) => {
      // console.log("dddddddddddddd")
      // console.log(data)

      if (data) {
        this.cmd = data;
      } else {
        alert('Aucune commande valide');
        location.href = '/commandes';
      }
    });
  }

  async getInfosUser() {
    await this.clientService.getUserInfos().then((res: any) => {
      // console.log("getUserInfos");
      // console.log(res);
      if (res) {
        this.client = res;
      }
    });
  }

  setTotalCmd(quantite, prixUnitaire) {
    // console.log("this.form.value.products : ")
    // console.log(this.form.value.products)

    this.totalCmd = quantite.value * prixUnitaire.value
    // this.form.value.products.forEach(prd => {
    //   if (prd.quantite != null && prd.prix_unitaire != null) {
    //     this.totalCmd += prd.quantite * prd.prix_unitaire
    //   }
    // });
  }

  async exchangeCommande(namePrd, quantite, prixUnitaire) {
    if (this.cmd.user_destination.nom_destination != "" && this.cmd.user_destination.adresse_destination != ""
      && this.cmd.user_destination.numtel_destination != ""
      && this.cmd.destination_cmd != '' && this.cmd.destination_cmd != 'Selectionnez une ville'
      && namePrd.value != "" && quantite.value != "" && prixUnitaire.value != "" && prixUnitaire.value >= 0) {
      let userId = await this.clientService.getIdUser()
      await this.cmdService.exchangeCmd(userId, this.cmd._id, this.cmd.user_destination.nom_destination, 
        this.cmd.user_destination.adresse_destination,
        this.cmd.user_destination.numtel_destination, this.cmd.destination_cmd, this.totalCmd, [{
          nom_prod: namePrd.value,
          quantite: quantite.value,
          prix_unitaire: prixUnitaire.value
        }])
        .then((res: any) => {
          // console.log("resssssssss : ")
          // console.log(res)
          if (res) {
            alert('Commande est ajoutée avec succès');
            // this.cleanData(nameDest, adressDest, numTelDest)
            //location.href = '/sacs';
            //this.sacsList = res;
          }
        });
    } else {
      alert('Commande est invalide');
    }
  }

}
