import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ClientsService } from 'src/app/services/clients/clients.service';
import { CommandesService } from 'src/app/services/commandes/commandes.service';

@Component({
  selector: 'app-add-commande',
  templateUrl: './add-commande.component.html',
  styleUrls: ['./add-commande.component.css']
})
export class AddCommandeComponent implements OnInit {

  villes = ["Selectionnez une ville",
    'Ariana',
    // 'Béja',
    'Ben Arous',
    // 'Bizerte',
    // 'Gabès',
    // 'Gafsa',
    // 'Jendouba',
    // 'Kairouan',
    // 'Kasserine',
    // 'Kébili',
    // 'Kef',
    'Mahdia',
    'Manouba',
    // 'Médenine',
    'Monastir',
    // 'Nabeul',
    'Sfax',
    // 'Sidi Bouzid',
    // 'Siliana',
    'Sousse',
    // 'Tataouine',
    // 'Tozeur',
    'Tunis',
    // 'Zaghouan'
  ];
  selectedVille: string = '';
  totalCmd: number = 0;
  frais: any = {}

  client: any = null
  form: FormGroup;

  constructor(private fb: FormBuilder,
    private clientService: ClientsService,
    private cmdService: CommandesService) {
    this.form = fb.group({
      products: fb.array([])
    })
  }

  async ngOnInit() {
    await this.getInfosUser()
    this.getFraisClient()
    this.addNewProductGroup()
  }

  async getFraisClient() {
    let userId = await this.clientService.getIdUser()
    await this.clientService.getFraisClient(userId)
      .then((res: any) => {
        // console.log("resssssssss :")
        // console.log(res)
        if (res) {
          this.frais = res
          //location.href = '/clients';
          //this.sacsList = res;
        }
      });
  }

  addNewProductGroup() {
    const add = this.form.get('products') as FormArray;

    add.push(this.fb.group({
      nom_prod: [],
      quantite: [],
      prix_unitaire: []
    }))
    // console.log(add)
  }

  async getInfosUser() {
    await this.clientService.getUserInfos().then((res: any) => {
      // console.log("getUserInfos");
      // console.log(res);
      if (res) {
        this.client = res;
      }
    });
  }

  deleteProductGroup(index: number) {
    const add = this.form.get('products') as FormArray;
    add.removeAt(index)
    this.setTotalCmd()
  }

  selectVille(event: any) {
    this.selectedVille = event.target.value;
  }

  setTotalCmd() {
    // console.log("this.form.value.products : ")
    // console.log(this.form.value.products)
    this.totalCmd = 0
    this.form.value.products.forEach(prd => {
      if (prd.quantite != null && prd.quantite>=0 && prd.prix_unitaire != null && prd.prix_unitaire>=0) {
        this.totalCmd += prd.quantite * prd.prix_unitaire
      }
    });
  }

  productsNotEmpty() {
    for (var i = 0; i < this.form.value.products.length; i++) {
      if (this.form.value.products[i].nom_prod == null || this.form.value.products[i].nom_prod == ""
        || this.form.value.products[i].quantite == null || this.form.value.products[i].quantite < 0
        || this.form.value.products[i].prix_unitaire == null || this.form.value.products[i].prix_unitaire < 0) {
        return false
      }
    }
    return true
  }

  async addCommande(nameDest, adressDest, numTelDest) {
    if (nameDest.value != "" && adressDest.value != ""
      && numTelDest.value != ""
      && this.selectedVille != '' && this.selectedVille != 'Selectionnez une ville'
      && this.form.value.products.length > 0 && this.productsNotEmpty()) {
      let userId = await this.clientService.getIdUser()
      await this.cmdService.addCmd(userId, nameDest.value, adressDest.value,
        numTelDest.value, this.selectedVille, this.totalCmd, /*this.frais.frais_externe, */this.form.value.products)
        .then((res: any) => {
          // console.log("resssssssss : ")
          // console.log(res)
          if (res) {
            alert('Commande est ajoutée avec succès');
            this.cleanData(nameDest, adressDest, numTelDest)
            //location.href = '/sacs';
            //this.sacsList = res;
          }
        });
    } else {
      alert('Commande est invalide');
    }
  }

  cleanData(nameDest, adressDest, numTelDest) {
    nameDest.value = ""
    adressDest.value = ""
    numTelDest.value = ""
    this.selectedVille = ""
    this.totalCmd = 0
    const add = this.form.get('products') as FormArray;
    while (add.length !== 0) {
      add.removeAt(0)
    }
    this.addNewProductGroup()
  }

}
