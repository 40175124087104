import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ClientsService } from 'src/app/services/clients/clients.service';
import { CommandesService } from 'src/app/services/commandes/commandes.service';

@Component({
  selector: 'app-detail-commande',
  templateUrl: './detail-commande.component.html',
  styleUrls: ['./detail-commande.component.css']
})
export class DetailCommandeComponent implements OnInit {

  cmd: any = {}
  cmdID: string = '';
  statusCmds: any = []
  client: any = null

  constructor(private route: ActivatedRoute,
    private clientService: ClientsService,
    private cmdsService: CommandesService,
    private router: Router) { }

  async ngOnInit() {
    this.cmdsService.getLabeledStatus().then(
      (lst) => {
        this.statusCmds = lst
      }
    )

    await this.getInfosUser();
    this.route.queryParams.subscribe((params) => {
      this.cmdID = params.cmd;
      this._fetchCmd();
      // console.log(this.cmd);
    });
  }

  goToNewTab(idCmd, route) {
    this.cmdsService.goToNewTab(idCmd, route)
  }

  _fetchCmd() {
    // console.log(cid);
    this.cmdsService.getCMD(this.cmdID).then((data: any) => {
      // console.log("dddddddddddddd")
      // console.log(data)

      if (data) {
        this.cmd = data;
      } else {
        alert('Aucune commande valide');
        location.href = '/commandes';
      }
    });
  }

  addComment(comment) {
    // console.log(cid);
    this.cmdsService.addComment(this.cmdID, this.client._id, 
          this.client.prenom_client + " " + this.client.nom_client, "client", comment.value).then((data: any) => {
      // console.log("dddddddddddddd")
      // console.log(data)

      if (data) {
        this.cmd = data;
        comment.value = ""
      }
    });
  }

  async getInfosUser() {
    await this.clientService.getUserInfos().then((res: any) => {
      //console.log("getUserInfos");
      //console.log(res);
      if (res) {
        this.client = res;
      }
    });
  }
  generatePDF() {
    var divContents = document.getElementById("commande").innerHTML;
    var a = window.open('', '', 'height=860, width=1600');
    a.document.write('<html><head> ');
    a.document.write(
      '<link rel="stylesheet" type="text/css" href="/assets/css/bootstrap.min.css" />'
    );
    a.document.write(
      '<link rel="stylesheet" type="text/css" href="/assets/css/style.css" />'
    );
    a.document.write(
      '<link rel="stylesheet" type="text/css" href="/assets/css/components.css" />'
    );
    a.document.write(
      '</head><body style="background:#FFFFFF" ><div  class="container invoice">'
    );

    a.document.write(divContents);
    a.document.write('</div></body></html>');
    a.document.close();
    a.print();
  }

}
