import { Component, HostBinding, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { select } from '@angular-redux/store';
import { Observable } from 'rxjs';
import { ClientsService } from 'src/app/services/clients/clients.service';
import { CommandesService } from 'src/app/services/commandes/commandes.service';
import { ThemeOptions } from 'src/app/theme-options';

@Component({
  selector: 'app-top',
  templateUrl: './top.component.html',
  styleUrls: ['./top.component.css']
})
export class TopComponent implements OnInit {

  @select('config') public config$: Observable<any>;

  private newInnerWidth: number;
  private innerWidth: number;

  isConnected = false
  client: any = null
  allComments: any = []

  constructor(private router: Router,
    private cmdsService: CommandesService,
    private clientService: ClientsService,
    public globals: ThemeOptions,) {
    this.isConnected = this.clientService.isConnected()
  }

  @HostBinding('class.isActive')
  get isActiveAsGetter() {
    return this.isActive;
  }

  isActive: boolean;

  async ngOnInit() {
    setTimeout(() => {
      this.innerWidth = window.innerWidth;
      if (this.innerWidth < 1200) {
        this.globals.toggleSidebar = true;
      }
    });

    await this.getInfosUser()
    await this.fetchComments()
  }

  async getInfosUser() {
    await this.clientService.getUserInfos().then((res: any) => {
      //console.log("getUserInfos");
      //console.log(res);
      if (res) {
        this.client = res;
      }
    });
  }

  async fetchComments() {
    await this.cmdsService.allComments(this.client._id).then((res: any) => {
      // console.log("fetchComments");
      // console.log(res);
      if (res) {
        this.allComments = res;
      }
    });
  }

  async deconnexion() {
    await this.clientService.deconnexion()
    // this.router.navigate(['/home'])
    window.location.reload()
    this.router.navigate(['/login'])
  }

  toggleSidebar() {
    console.log("toggleSidebar")
    console.log(this.globals.toggleSidebar)
    this.globals.toggleSidebar = !this.globals.toggleSidebar;
  }

  sidebarHover() {
    console.log("sidebarHover")
    console.log(this.globals.sidebarHover)
    this.globals.sidebarHover = !this.globals.sidebarHover;
    // if(this.globals.sidebarHover) {
    //   this.globals.toggleSidebar = true
    //   this.globals.sidebarHover = false
    // }
  }

  toggleSidebarMobile() {
    console.log("toggleSidebarMobile")
    console.log(this.globals.toggleSidebarMobile)
    this.globals.toggleSidebarMobile = !this.globals.toggleSidebarMobile;
    if(this.globals.toggleSidebarMobile) {
      this.globals.toggleHeaderMobile = false
    }
  }

  toggleHeaderMobile() {
    console.log("toggleHeaderMobile")
    this.globals.toggleHeaderMobile = !this.globals.toggleHeaderMobile;
    if(this.globals.toggleHeaderMobile) {
      this.globals.toggleSidebarMobile = false
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.newInnerWidth = event.target.innerWidth;

    if (this.newInnerWidth < 1200) {
      this.globals.toggleSidebar = true;
    } else {
      this.globals.toggleSidebar = false;
    }

  }

}
