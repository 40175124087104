<div [class]="'app-header header-shadow bg-strong-bliss header-text-light ' + (config$ | async).headerTheme" 
(mouseover)="sidebarHover()" (mouseout)="sidebarHover()">
    <div class="app-header__logo">
        <div class="logo-src"></div>
        <div class="header__pane ml-auto">
            <button type="button" class="hamburger close-sidebar-btn hamburger--elastic"
                [ngClass]="{'is-active' : globals.toggleSidebar}" (click)="toggleSidebar()">
                <span class="hamburger-box">
                    <span class="hamburger-inner"></span>
                </span>
            </button>
        </div>
    </div>
    <div class="app-header__mobile-menu">
        <button type="button" class="hamburger close-sidebar-btn hamburger--elastic"
            [ngClass]="{'is-active' : globals.toggleSidebarMobile}" 
            (click)="toggleSidebarMobile()">
            <span class="hamburger-box">
                <span class="hamburger-inner"></span>
            </span>
        </button>
    </div>
    <div class="app-header__menu">
        <span>
            <button class="btn-icon btn-icon-only btn btn-primary"
                [ngClass]="{'is-active' : globals.toggleHeaderMobile}" 
                (click)="toggleHeaderMobile()">
                <div class="btn-icon-wrapper">
                    <i class="fas fa-ellipsis-v"></i>
                </div>
            </button>
        </span>
    </div>
    <div class="app-header__content" [ngClass]="{'header-mobile-open' : globals.toggleHeaderMobile}">
        <div class="app-header-left">
            <div class="search-wrapper active">
                <div class="input-holder">
                    <input type="text" class="search-input" placeholder="Type to search">
                    <button class="search-icon"><span></span></button>
                </div>
                <!-- <button class="close"></button> -->
            </div>
            <!-- <ul class="header-menu nav">
                <li class="nav-item">
                    <a href="javascript:void(0);" class="nav-link">
                        <i class="nav-link-icon fa fa-database"> </i>
                        Statistics
                    </a>
                </li>
                <li class="btn-group nav-item">
                    <a href="javascript:void(0);" class="nav-link">
                        <i class="nav-link-icon fa fa-edit"></i>
                        Projects
                    </a>
                </li>
                <li class="dropdown nav-item">
                    <a href="javascript:void(0);" class="nav-link">
                        <i class="nav-link-icon fa fa-cog"></i>
                        Settings
                    </a>
                </li>
            </ul> -->
        </div>
        <div class="app-header-right">
            <div class="header-btn-lg pr-0">
                <div class="widget-content p-0">
                    <div class="widget-content-wrapper">
                        <ul class="header-menu nav">
                            <li class="dropdown dropdown-list-toggle" ngbDropdown placement="bottom-right">
                                <a class="nav-link notification-toggle nav-link-lg" ngbDropdownToggle><i
                                        class="far fa-bell"></i></a>
                                <div class="dropdown-menu dropdown-list dropdown-menu-right" ngbDropdownMenu>
                                    <div class="dropdown-header">Notifications
                                        <div class="float-right">
                                            <a href="#">Mark All As Read</a>
                                        </div>
                                    </div>
                                    <div class="dropdown-list-content dropdown-list-icons" style="overflow-y:auto;">
                                        <!-- <a href="#" class="dropdown-item dropdown-item-unread">
                                            <div class="dropdown-item-icon bg-primary text-white">
                                                <i class="fas fa-code"></i>
                                            </div>
                                            <div class="dropdown-item-desc">
                                                Template update is available now!
                                                <div class="time text-primary">2 Min Ago</div>
                                            </div>
                                        </a>
                                        <a href="#" class="dropdown-item">
                                            <div class="dropdown-item-icon bg-info text-white">
                                                <i class="far fa-user"></i>
                                            </div>
                                            <div class="dropdown-item-desc">
                                                <b>You</b> and <b>Dedik Sugiharto</b> are now friends
                                                <div class="time">10 Hours Ago</div>
                                            </div>
                                        </a> -->

                                        <div *ngFor="let cmd of allComments, let index = index">
                                            <a [routerLink]="['/detail-cmd']"
                                            [queryParams]="{ cmd: cmd._id }" [ngClass]="(index % 2===1)?'dropdown-item':'dropdown-item dropdown-item-unread'">
                                                <div class="dropdown-item-icon text-white">
                                                    <!-- <i class="fas fa-code"></i> -->
                                                    <ngx-avatar width="42" class="rounded-circle" name="{{cmd.comments[cmd.comments.length-1].name_user}} ">
                                                    </ngx-avatar>
                                                </div>
                                                <div class="dropdown-item-desc">
                                                    <!-- <div class="time text-primary">2 Min Ago</div> -->
                                                    <div class="time text-primary">{{ cmd.comments[cmd.comments.length-1].name_user }} 
                                                        <!-- <strong>({{cmd.comments[cmd.comments.length-1].type_user}})</strong> -->
                                                    </div>
                                                    {{cmd.comments[cmd.comments.length-1].content}}
                                                </div>
                                                <div class="ml-auto badge badge-pill badge-danger">{{cmd.comments.length}}</div>
                                            </a>
                                        </div>
                                    </div>
                                    <div class="dropdown-footer text-center">
                                        <a href="#">View All <i class="fas fa-chevron-right"></i></a>
                                    </div>
                                </div>
                            </li>
                        </ul>
                        <!-- <ul class="header-menu nav">
                            <li class="nav-item">
                                <button type="button" aria-haspopup="true" aria-expanded="false" data-toggle="dropdown"
                                    class="nav-link mb-2 mr-2 dropdown-toggle btn btn-link">
                                    <i class="nav-link-icon pe-7s-bell"> </i>
                                </button>
                                <div tabindex="-1" role="menu" aria-hidden="true" class="dropdown-menu">
                                    <button type="button" tabindex="0" class="dropdown-item">Menus</button>
                                    <button type="button" tabindex="0" class="dropdown-item">Settings</button>
                                    <h6 tabindex="-1" class="dropdown-header">Header</h6>
                                    <button type="button" tabindex="0" class="dropdown-item">Actions</button>
                                    <div tabindex="-1" class="dropdown-divider"></div>
                                    <button type="button" tabindex="0" class="dropdown-item">Dividers</button>
                                </div>
                            </li>
                        </ul> -->
                        <div class="widget-content-left">
                            <div class="btn-group margin-icon-user" ngbDropdown placement="bottom-right">
                                <a class="btn btn-link p-0 mr-2" ngbDropdownToggle>
                                    <ngx-avatar width="42" class="rounded-circle"
                                        name="{{client?.prenom_client + ' ' + client?.nom_client}}">
                                        <!-- <i class="fa fa-angle-down ml-2 opacity-8"></i> -->
                                    </ngx-avatar>
                                    <!-- <img width="42" class="rounded-circle" src="assets/images/avatars/1.jpg" alt=""> -->

                                </a>
                                <div ngbDropdownMenu>
                                    <ul class="nav flex-column">
                                        <li class="nav-item">
                                            <div class="nav-link">
                                                <!-- <i class="metismenu-icon "></i> -->
                                                <i class="nav-link-icon pe-7s-lock"></i>
                                                <!-- <span> -->
                                                <button type="button" tabindex="0" class="dropdown-item"
                                                    (click)="deconnexion()">Déconnexion</button>
                                                <!-- </span> -->
                                                <!-- <div class="ml-auto badge badge-pill badge-secondary">86</div> -->
                                            </div>
                                        </li>
                                    </ul>

                                    <!-- <button type="button" tabindex="0" class="dropdown-item">User Account</button>
                                    <button type="button" tabindex="0" class="dropdown-item">Settings</button>
                                    <h6 tabindex="-1" class="dropdown-header">Header</h6>
                                    <button type="button" tabindex="0" class="dropdown-item">Actions</button>
                                    <div tabindex="-1" class="dropdown-divider"></div> -->
                                    <!-- <div class="row">
                                        <div class="col-3">
                                            <i class="metismenu-icon pe-7s-cash"></i>
                                        </div>
                                        <div class="col-9">
                                            <i class="metismenu-icon pe-7s-cash"></i>
                                            <button type="button" tabindex="0" class="dropdown-item"
                                                (click)="deconnexion()">Déconnexion</button>
                                        </div>
                                    </div> -->
                                </div>
                            </div>
                        </div>
                        <div class="widget-content-left  ml-3 header-user-info">
                            <div class="widget-heading">
                                {{client?.prenom_client}} {{client?.nom_client}}
                            </div>
                            <div class="widget-subheading">
                                {{client?.company_name}}
                            </div>
                        </div>
                        <!-- <div class="widget-content-right header-user-info ml-3">
                            <button type="button" class="btn-shadow p-1 btn btn-primary btn-sm show-toastr-example">
                                <i class="fa text-white fa-calendar pr-1 pl-1"></i>
                            </button>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>