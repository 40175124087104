import { Component, OnInit } from '@angular/core';
import { ClientsService } from 'src/app/services/clients/clients.service';
import { CommandesService } from 'src/app/services/commandes/commandes.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  dataCmds: any = {}

  // listCmds: any = []
  // nbrCmdsValidated: any = 0
  // nbrCmdsInDepot: any = 0
  // nbrCmdsPrepared: any = 0
  // nbrCmdsDelivered: any = 0
  // nbrCmdsPaied: any = 0
  // nbrCmdsReturned: any = 0
  // nbrCmdsReturnedExp: any = 0
  // nbrCmdsReturnedRecu: any = 0
  // nbrCmdsEnCours: any = 0

  constructor(private cmdsService: CommandesService,
    private clientService: ClientsService) { }

  ngOnInit() {
    this.listCmdsByStatus()
    // console.log("***************************")

    // this.nbrCmdsValidated = this.listCmdsClient(["validated"]);
    // this.nbrCmdsInDepot = this.listCmdsClient(["transporting_depot"]);
    // this.nbrCmdsPrepared = this.listCmdsClient(["prepared"]);
    // this.nbrCmdsDelivered = this.listCmdsClient(["delivered"])
    // this.nbrCmdsPaied = this.listCmdsClient(["paied"])
    // this.nbrCmdsReturned = this.listCmdsClientOtherParam(["returned"], "returned_client")
    // this.nbrCmdsReturnedExp = this.listCmdsClientOtherParam(["returned"], "returned_exp")
    // this.nbrCmdsReturnedRecu = this.listCmdsClientOtherParam(["returned"], "returned_recu")
    // this.nbrCmdsEnCours = this.listCmdsClient(["transporting", "transported", "arrived"])
  }

  // async listCmdsClient(status) {
  //   let userId = await this.clientService.getIdUser()
  //   this.listCmds = await this.cmdsService.allCmdsClientByStatus(userId, status)
  //   // if (this.listCmds)
  //   console.log(this.listCmds.length)
  //   return this.listCmds.length
  //   // else
  //   //   return 0
  // }

  async listCmdsByStatus() {
    let userId = await this.clientService.getIdUser()
    this.cmdsService.allCmdsByStatus(userId).then((res: any) => {
      // console.log(res)
      if (res) {
        this.dataCmds = res;
      }
    });
  }

  // async listCmdsClientOtherParam(status, returnedBy) {
  //   let userId = await this.clientService.getIdUser()
  //   this.listCmds = await this.cmdsService.allCmdsClientByStatus(userId, status)
    
  //   this.listCmds = this.listCmds.filter(cmd => cmd.returned_cmd==returnedBy);
  //   // if (this.listCmds)
  //   return this.listCmds.length
  //   // else
  //   //   return 0
  // }

}
