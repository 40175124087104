import { Component, OnInit } from '@angular/core';
import { ClientsService } from 'src/app/services/clients/clients.service';
import { CommandesService } from 'src/app/services/commandes/commandes.service';

@Component({
  selector: 'app-add-commande-mb',
  templateUrl: './add-commande-mb.component.html',
  styleUrls: ['./add-commande-mb.component.css']
})
export class AddCommandeMbComponent implements OnInit {

  cmd: any = {}

  constructor(private cmdService: CommandesService,
    private clientService: ClientsService,) { }

  ngOnInit(): void {
  }

  async addCommande(refCmd) {
    await this.cmdService.getOneCMD(refCmd.value)
      .then((res: any) => {
        // console.log("resssssssss : ")
        // console.log(res)
        if (res) {
          this.cmd = res
          //location.href = '/sacs';
          //this.sacsList = res;
        }
      });
    let products = []
    this.cmd.basket.prdts.forEach(prd => {
      products.push({
        nom_prod: prd.nom,
        quantite: prd.quantite,
        prix_unitaire: prd.prix_unitaire
      })
    });
    
    let userId = await this.clientService.getIdUser()
    await this.cmdService.addCmd(userId, this.cmd.facturation.nom, this.cmd.facturation.adresse,
      this.cmd.facturation.numtel, this.cmd.facturation.ville, this.cmd.total_ca_cmd, products)
      .then((res: any) => {
        // console.log("resssssssss : ")
        // console.log(res)
        if (res) {
          //location.href = '/sacs';
          //this.sacsList = res;
        }
      });
  }

}
