import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import * as XLSX from 'xlsx';

import { Workbook } from 'exceljs';
import * as fs from 'file-saver';
import * as logoFile from './logo.js';
import { DatePipe, DecimalPipe } from '@angular/common';
// import { DatePipe } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class CommandesService {



  constructor(private http: HttpClient,
    private router: Router,
    private _decimalPipe: DecimalPipe,
    private _datePipe: DatePipe) { }

  // get cmd from api.marketbey
  getOneCMD(ref) {
    return new Promise((slv) => {
      this.http.post(environment.apiOneCmdByID, { ref }).subscribe(
        (data: any) => {
          // console.log(JSON.stringify(data));
          if (data && data.response && data.data) {
            slv(data.data);
          } else slv(false);
        },
        (error) => {
          // console.log("Cmd error")
          // console.log(error)
          slv(false);
        }
      );
    });
  }

  getCMD(ref) {
    // console.log("ref : " + ref)
    return new Promise((slv) => {
      this.http.post(environment.apiOneCmd + "/" + ref, {}).subscribe(
        (data: any) => {
          // console.log(data);
          if (data && data.response && data.data) {
            slv(data.data);
          } else slv(false);
        },
        (error) => {
          // console.log("Cmd error")
          // console.log(error)
          slv(false);
        }
      );
    });
  }

  allComments(idClient) {
    return new Promise((slv) => {
      this.http.post(environment.apiAllComments, {
        client: idClient
      }).subscribe(
        (data: any) => {
          // console.log(data);
          if (data && data.response && data.data) {
            slv(data.data);
          } else slv(null);
        },
        (error) => {
          // console.log("Cmd error")
          // console.log(error)
          slv(null);
        }
      );
    });
  }

  addComment(cmd, idUser, nameUser, typeUser, comment) {
    return new Promise((slv) => {
      this.http.post(environment.apiAddComment, {
        cmd,
        idUser,
        nameUser,
        typeUser,
        comment
      }).subscribe(
        (data: any) => {
          // console.log(data);
          if (data && data.response && data.data) {
            slv(data.data);
          } else slv(false);
        },
        (error) => {
          // console.log("Cmd error")
          // console.log(error)
          slv(false);
        }
      );
    });
  }

  getLabeledStatus() {
    // ["pending", "validated", "preparing", "submited", "transported", "arrived", "delivered", "returned", "refused", "canceled"],
    return new Promise((slv) => {
      this.http.post(environment.apiStatus, {}).subscribe(
        (data: any) => {
          // console.log(JSON.stringify(data));
          if (data && data.response && data.data) {
            slv(data.data);
          } else slv(false);
        },
        (error) => {
          // console.log("Cmd error")
          // console.log(error)
          slv(false);
        }
      );
    });
  }

  async getCmdsByClient(idClient) {
    return await new Promise((slv) => {
      this.http.post(environment.apiAllCmdsByClient, { idClient: idClient }).subscribe(
        (data: any) => {
          if (data && data.response && data.data) {
            slv(data.data);
          } else slv(false);
        },
        (error) => {
          slv(false);
        }
      );
    });
  }

  allCmds() {
    return new Promise((slv) => {
      this.http.post(environment.apiAllCmds, {}).subscribe(
        (data: any) => {
          if (data && data.response && data.data) {
            slv(data.data);
          } else slv(false);
        },
        (error) => {
          // console.log("Cmd error")
          // console.log(error)
          slv(false);
        }
      );
    });
  }

  allCmdsClientByStatus(page, idClient, status) {
    let listStatus = []
    status.forEach(element => {
      listStatus.push({ etat_cmd: element })
    });

    return new Promise((slv) => {
      this.http.post(environment.apiAllCmdsByStatusAndClient, {
        page,
        client: idClient,
        status: listStatus
      }).subscribe(
        (data: any) => {
          if (data && data.response && data.data) {
            slv(data.data);
          } else slv(false);
        },
        (error) => {
          // console.log("Cmd error")
          // console.log(error)
          slv(false);
        }
      );
    });
  }

  allCmdsByStatus(idClient) {
    return new Promise((slv) => {
      this.http.post(environment.apiAllCmdsByStatus, {
        client: idClient
      }).subscribe(
        (data: any) => {
          if (data && data.response && data.data) {
            slv(data.data);
          } else slv(false);
        },
        (error) => {
          // console.log("Cmd error")
          // console.log(error)
          slv(false);
        }
      );
    });
  }

  searchCmd(idClient, keyword) {
    return new Promise((slv) => {
      this.http.post(environment.apiSearchCmd, {
        client: idClient,
        keyword: keyword
      }).subscribe(
        (data: any) => {
          if (data && data.response && data.data) {
            slv(data.data);
          } else slv(false);
        },
        (error) => {
          // console.log("Cmd error")
          // console.log(error)
          slv(false);
        }
      );
    });
  }

  filterCmds(page, idClient, ref, status, ville, numero, datein, datefin) {
    // console.log("pageeeeeee: " + page)
    return new Promise((slv) => {
      this.http
        .post(environment.apiFilterCmds, {
          page,
          client: idClient,
          ref: ref,
          status: status,
          ville: ville,
          numtel: numero,
          startDate: datein,
          endDate: datefin
        })
        .subscribe(
          (data: any) => {
            if (data && data.response && data.data) {
              slv(data.data);
            } else slv(false);
          },
          (error) => {
            slv(false);
          }
        );
    });
  }

  addCmd(idExp, nameDest, adressDest, numTelDest, destination, totalCmd, /*fraisLivraison, */products) {
    var cmd = {
      idExp,
      nameDest,
      adressDest,
      numTelDest,
      destination,
      totalCmd,
      // fraisLivraison,
      products
    }
    return new Promise((slv) => {
      this.http.post(environment.apiAddCmd, cmd).subscribe(
        (data: any) => {
          if (data && data.response) {
            slv(true);
          } else slv(false);
        },
        (error) => {
          // console.log("Cmd error")
          // console.log(error)
          slv(false);
        }
      );
    });
  }

  exchangeCmd(idExp, idCmd, nameDest, adressDest, numTelDest, destination, totalCmd, /*fraisLivraison, */products) {
    var cmd = {
      idExp,
      idCmd,
      nameDest,
      adressDest,
      numTelDest,
      destination,
      totalCmd,
      // fraisLivraison,
      products
    }
    return new Promise((slv) => {
      this.http.post(environment.apiExchangeCmd, cmd).subscribe(
        (data: any) => {
          if (data && data.response) {
            slv(true);
          } else slv(false);
        },
        (error) => {
          // console.log("Cmd error")
          // console.log(error)
          slv(false);
        }
      );
    });
  }

  // addCmds(cmds) {
  //   cmds.forEach(cmd => {
      
  //   });
  // }

  statisticsCmds(client, startDate, endDate, ville) {
    return new Promise((slv) => {
      this.http.post(environment.apiStatsCmds, {
        client,
        startDate,
        endDate,
        ville
      }).subscribe(
        (data: any) => {
          if (data && data.response && data.data) {
            slv(data.data);
          } else slv(false);
        },
        (error) => {
          slv(false);
        }
      );
    });
  }

  updateCmdStatus(id, status) {
    return new Promise((slv) => {
      this.http
        .post(environment.apiUpdateCmdStatus, {
          cmd: id,
          newstatus: status,
        })
        .subscribe(
          (data: any) => {
            // console.log("data update status : ");
            // console.log(data);
            if (data && data.response) {
              slv(true);
            } else slv(false);
          },
          (error) => {
            slv(false);
          }
        );
    });
  }

  goToNewTab(idCmd, route) {
    const queryParams = {
      cmd: idCmd
    };
    const url = this.router.serializeUrl(
      this.router.createUrlTree([route], {
        queryParams: queryParams,
        // relativeTo: this.activeRoute,
        // skipLocationChange: true
      })
    );

    window.open(url, '_blank');
  }

  public exportToFile(fileName: string, element_id: string) {
    if (!element_id) throw new Error('Element Id does not exists');

    let tbl = document.getElementById(element_id);
    let wb = XLSX.utils.table_to_book(tbl);
    XLSX.writeFile(wb, fileName + '.xlsx');
  }

  exportExcel(excelData) {

    //Title, Header & Data
    const title = excelData.title;
    const header = excelData.headers
    const data = excelData.data;
    const etatCmd = excelData.etatCmd;
    const startDate = excelData.startDate;
    const endDate = excelData.endDate;

    //Create a workbook with a worksheet
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet('Sales Data');


    //Add Row and formatting
    worksheet.mergeCells('C1', 'F5');
    let titleRow = worksheet.getCell('C1');
    titleRow.value = title
    titleRow.font = {
      name: 'Calibri',
      size: 20,
      underline: 'single',
      bold: true,
      color: { argb: '0085A3' }
    }
    titleRow.alignment = { vertical: 'middle', horizontal: 'center' }

    // Date
    worksheet.mergeCells('G1:G5');
    let d = new Date();
    let date = d.getDate() + '-' + (d.getMonth() + 1) + '-' + d.getFullYear();
    let dateCell = worksheet.getCell('G1');
    dateCell.value = date;
    dateCell.font = {
      name: 'Calibri',
      size: 12,
      bold: true
    }
    dateCell.alignment = { vertical: 'middle', horizontal: 'center' }

    //Add Image
    let myLogoImage = workbook.addImage({
      base64: logoFile.logoBase64,
      extension: 'png',
    });
    worksheet.mergeCells('A2:B4');
    worksheet.addImage(myLogoImage, 'A2:B4');

    //Blank Row 
    worksheet.getColumn(1).width = 7;
    worksheet.getColumn(2).width = 15;
    worksheet.getColumn(3).width = 20;
    worksheet.getColumn(4).width = 40;
    worksheet.getColumn(5).width = 40;
    worksheet.getColumn(6).width = 12;
    worksheet.getColumn(7).width = 20;
    worksheet.addRow([]);

    let subTitleRow = worksheet.addRow(['La liste des commandes ' + etatCmd + ' - de ' + startDate + " jusqu'à " + endDate]);
    subTitleRow.getCell(1).font = {
      bold: true,
      // color: { argb: 'FFFFFF' },
      size: 12
    };
    subTitleRow.alignment = { vertical: 'middle', horizontal: 'center' }
    // subTitleRow.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }

    //Merge Cells
    worksheet.mergeCells(`A${subTitleRow.number}:G${subTitleRow.number}`);

    worksheet.addRow([]);

    //Adding Header Row
    let headerRow = worksheet.addRow(header);
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '4167B8' },
        bgColor: { argb: '' }
      }
      cell.font = {
        bold: true,
        color: { argb: 'FFFFFF' },
        size: 12
      }
    })

    // Adding Data with Conditional Formatting
    let index = 0
    let total = 0
    data.forEach(cmd => {
      // console.log(cmd)
      index++;
      total += cmd.total_ca_cmd + cmd.frais_livraison
      let row = worksheet.addRow([index.toString(),
      cmd.ref_facture_cmd,
      this._datePipe.transform(cmd.date_creation_cmd, "d MMM y h:mm"),
      cmd.user_destination?.nom_destination + " - " + cmd.user_destination.numtel_destination,
      cmd.user_destination?.adresse_destination,
      cmd.destination_cmd,
      this._decimalPipe.transform((cmd.total_ca_cmd + cmd.frais_livraison), "0.3") + " DT"
      ]);
      // let row = worksheet.addRow(d);

      let cities = row.getCell(6);
      cities.font = {
        bold: true,
        // color: { argb: 'FFFFFF' },
        // size: 12
      }
      cities.alignment = { vertical: 'middle', horizontal: 'center' }

      let references = row.getCell(2);
      references.font = {
        bold: true,
        // color: { argb: 'FFFFFF' },
        // size: 12
      }

      let sales = row.getCell(7);
      let color = 'FF99FF99';
      // if (+sales.value < 200000) {
      //   color = 'FF9999'
      // }
      sales.font = {
        bold: true,
        // color: { argb: 'FFFFFF' },
        size: 12
      }
      sales.alignment = { vertical: 'middle', horizontal: 'right' }
      sales.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: color }
      }
    }
    );

    // worksheet.getColumn(3).width = 20;
    let endArray = worksheet.addRow([]);

    let totalCell = worksheet.getCell(`G${endArray.number+1}`);
    totalCell.value = this._decimalPipe.transform(total, "0.3") + " DT"
    totalCell.font = {
      bold: true,
      // color: { argb: 'FFFFFF' },
      size: 12
    }
    totalCell.alignment = { vertical: 'middle', horizontal: 'right' }
    totalCell.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'FF99FF99' }
    }
    totalCell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }

    worksheet.addRow([]);

    //Footer Row
    // let footerRow = worksheet.addRow(['Employee Sales Report Generated from example.com at ' + date]);
    // footerRow.getCell(1).fill = {
    //   type: 'pattern',
    //   pattern: 'solid',
    //   fgColor: { argb: 'FFB050' }
    // };

    // //Merge Cells
    // worksheet.mergeCells(`A${footerRow.number}:F${footerRow.number}`);

    //Generate & Save Excel File
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, "Statistiques__"+etatCmd+"__"+startDate+"_"+endDate + '.xlsx');
    })

  }
}
