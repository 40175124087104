<div class="app-page-title">
    <div class="page-title-wrapper">
        <div class="page-title-heading">
            <div class="page-title-icon">
                <i class="pe-7s-box2 icon-gradient bg-mean-fruit">
                </i>
            </div>
            <div>Commande
                <div class="page-title-subheading">Ajouter une nouvelle commande.
                </div>
            </div>
        </div>
        <!-- <div class="page-title-actions">
            <button type="button" data-toggle="tooltip" title="Example Tooltip" data-placement="bottom"
                class="btn-shadow mr-3 btn btn-dark">
                <i class="fa fa-star"></i>
            </button>
            <div class="d-inline-block dropdown">
                <button type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                    class="btn-shadow dropdown-toggle btn btn-info">
                    <span class="btn-icon-wrapper pr-2 opacity-7">
                        <i class="fa fa-business-time fa-w-20"></i>
                    </span>
                    Buttons
                </button>
                <div tabindex="-1" role="menu" aria-hidden="true"
                    class="dropdown-menu dropdown-menu-right">
                    <ul class="nav flex-column">
                        <li class="nav-item">
                            <a href="javascript:void(0);" class="nav-link">
                                <i class="nav-link-icon lnr-inbox"></i>
                                <span>
                                    Inbox
                                </span>
                                <div class="ml-auto badge badge-pill badge-secondary">86</div>
                            </a>
                        </li>
                        <li class="nav-item">
                            <a href="javascript:void(0);" class="nav-link">
                                <i class="nav-link-icon lnr-book"></i>
                                <span>
                                    Book
                                </span>
                                <div class="ml-auto badge badge-pill badge-danger">5</div>
                            </a>
                        </li>
                        <li class="nav-item">
                            <a href="javascript:void(0);" class="nav-link">
                                <i class="nav-link-icon lnr-picture"></i>
                                <span>
                                    Picture
                                </span>
                            </a>
                        </li>
                        <li class="nav-item">
                            <a disabled href="javascript:void(0);" class="nav-link disabled">
                                <i class="nav-link-icon lnr-file-empty"></i>
                                <span>
                                    File Disabled
                                </span>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div> -->
    </div>
</div>

<div class="main-card mb-3 card">
    <div class="card-body">
        <h5 class="card-title">L'expéditeur</h5>
        <!-- <form class="" action="" method="post" onsubmit="return false;"
            (onsubmit)="addCommande(nameDest, adressDest, numTelDest);"> -->
            <div class="position-relative row form-group">
                <div class="col-sm-1"></div>
                <div class="col-sm-5">
                    <!-- <legend for="client" class="space-bottom">L'expéditeur</legend> -->
                    <input name="nameCompany" id="nameCompany" type="text" disabled class="form-control"
                        [value]="client?.prenom_client">
                </div>

                <div class="col-sm-5">
                    <input name="nameExp" id="nameExp" type="text" disabled class="form-control"
                        [value]="client?.nom_client">
                </div>
            </div>
        <!-- </form> -->

    </div>
</div>

<div class="tab-content">
    <div class="main-card mb-12 card">
        <div class="card-body">
            <h5 class="card-title">Détail commande</h5>
            <form class="" action="" method="post" onsubmit="return false;"
                (onsubmit)="exchangeCommande(namePrd, quantite, prixUnitaire);">
                <!-- <div class="position-relative row form-group">
                    <legend for="client" class="space-bottom">L'expéditeur</legend>

                    <div class="col-sm-6">
                        <input name="nameCompany" id="nameCompany" type="text" disabled
                            class="form-control" [value]="client?.prenom_client">
                    </div>

                    <div class="col-sm-6">
                        <input name="nameExp" id="nameExp" type="text" disabled class="form-control"
                            [value]="client?.nom_client">
                    </div>
                </div> -->

                <div class="position-relative row form-group">
                    
                    <div class="col-sm-4">
                        <legend for="client" class="space-bottom">Nom de destinataire</legend>
                        <input name="nameDest" id="nameDest" type="text" class="form-control"
                            placeholder="Nom de destinataire" [(ngModel)]="cmd.user_destination.nom_destination">
                        <mat-error style="color: red;font-weight: bold;" *ngIf="cmd.user_destination.nom_destination==''">
                            Ajoutez le nom du client
                        </mat-error>
                    </div>

                    <div class="col-sm-4">
                        <legend for="client" class="space-bottom">Adresse du client</legend>
                        <input name="adressDest" id="adressDest" type="text" class="form-control"
                            placeholder="Adresse du client" [(ngModel)]="cmd.user_destination.adresse_destination">
                        <mat-error style="color: red;font-weight: bold;" *ngIf="cmd.user_destination.adresse_destination==''">
                            Ajoutez l'adresse du client
                        </mat-error>
                    </div>

                    <div class="col-sm-4">
                        <legend for="client" class="space-bottom">Numéro de téléphone</legend>
                        <input name="numTelDest" id="numTelDest" type="number" class="form-control"
                            placeholder="Numéro de téléphone" [(ngModel)]="cmd.user_destination.numtel_destination">
                        <mat-error style="color: red;font-weight: bold;"
                            *ngIf="cmd.user_destination.numtel_destination=='' && cmd.user_destination.numtel_destination.toString()!=8">
                            Ajoutez le numéro de téléphone du client
                        </mat-error>
                    </div>
                </div>

                <div class="position-relative row form-group">
                    <!-- <legend for="destination" class="space-bottom">Commande </legend> -->

                    <div class="col-sm-6">
                        <legend for="client" class="space-bottom">Destination de la commande</legend>
                        <select class="form-control" name="destination_cmd" [(ngModel)]="cmd.destination_cmd">
                            <option [value]="ville" *ngFor="let ville of villes">
                                {{ville}}</option>
                        </select>
                        <mat-error style="color: red;font-weight: bold;"
                            *ngIf="cmd.destination_cmd=='' || cmd.destination_cmd=='Selectionnez une ville'">
                            Ajoutez la ville d'adresse
                        </mat-error>
                    </div>

                    <div class="col-sm-6">
                        <legend for="client" class="space-bottom">Total de la commande</legend>
                        <input name="totalCmd" id="totalCmd" type="number" class="form-control" disabled
                            [(ngModel)]="totalCmd">
                    </div>
                </div>

                <div class="position-relative row form-group">
                    <div class="col-12">
                        <legend for="client" class="space-bottom">Produit à changer</legend>
                        <p *ngFor="let prd of cmd.products, let index = index">
                            {{index+1}} - {{prd.nom_prod}} <strong>({{prd.quantite}} article(s))</strong></p>
                    </div>
                </div>

                <div class="position-relative row form-group">
                    <div class="col-sm-5">
                        <input type="text" name="namePrd" id="namePrd" class="form-control" 
                        placeholder="Nom du produit" #namePrd ngModel>
                    </div>

                    <div class="col-sm-3">
                        <input type="number" name="quantite" id="quantite" class="form-control" 
                        placeholder="Quantité" #quantite ngModel (input)="setTotalCmd(quantite, prixUnitaire)">
                    </div>

                    <div class="col-sm-3">
                        <input type="number" name="prixUnitaire" id="prixUnitaire" class="form-control" 
                        placeholder="Prix unitaire" #prixUnitaire ngModel (input)="setTotalCmd(quantite, prixUnitaire)">
                    </div>

                    <mat-error style="color: red;font-weight: bold;"
                        *ngIf="namePrd.value == '' || quantite.value == '' || prixUnitaire.value == '' || (prixUnitaire.value != '' && prixUnitaire.value < 0)">
                        Les produits sont invalid
                    </mat-error>
                </div>

                <!-- <div [formGroup]="form">
                    <legend for="client">Produits</legend>
                    <div class="position-relative row form-group"
                        *ngFor="let productGroup of form.get('products')['controls'];let i = index"
                        [formGroup]="productGroup">

                        <div class="col-sm-5">
                            <input type="text" class="form-control" placeholder="Nom du produit"
                                formControlName="nom_prod">
                        </div>

                        <div class="col-sm-3">
                            <input type="number" class="form-control" placeholder="Quantité"
                                formControlName="quantite" (input)="setTotalCmd()">
                        </div>

                        <div class="col-sm-3">
                            <input type="number" class="form-control" placeholder="Prix unitaire"
                                formControlName="prix_unitaire" (input)="setTotalCmd()">
                        </div>

                        <div class="col-sm-1">
                            <button type="button" (click)="deleteProductGroup(i)"
                                class="mr-2 btn-icon btn-icon-only btn btn-danger"><i
                                    class="pe-7s-trash btn-icon-wrapper"> </i></button>
                        </div>

                    </div>
                </div> -->
                <!-- 
                <br>
                <br>
                <br>
                <br>
                result <br>

                <pre>
                    {{form.value | json}}
                </pre> -->

                <div class="position-relative row form-check">
                    <div class="col-sm-10 offset-sm-10">
                        <button type="submit" (click)="exchangeCommande(namePrd, quantite, prixUnitaire)"
                            class="btn btn-primary">Ajouter
                            commande</button>
                    </div>
                </div>
            </form>

        </div>
    </div>
</div>